import "./bootstrap"
import "../css/app.css"
import "../scss/theme.scss"
//Import Froala Editor
import "froala-editor/js/plugins.pkgd.min.js"
//Import third party plugins
import "froala-editor/js/third_party/embedly.min"
import "froala-editor/js/third_party/font_awesome.min"
import "froala-editor/js/third_party/spell_checker.min"
import "froala-editor/js/third_party/image_tui.min"
// Import Froala Editor css files.
import "froala-editor/css/froala_editor.pkgd.min.css"
import "froala-editor/css/froala_style.min.css"
// import "@fortawesome/fontawesome-pro/css/all.css"
import "vue3-colorpicker/style.css"
import VueGridLayout from "vue-grid-layout"

import { createApp, h, onUnmounted } from "vue"
import { createInertiaApp, router } from "@inertiajs/vue3"
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"
import { registerPlugins } from "@/Plugins"
import VueFroala from "vue-froala-wysiwyg"
import FakeRouterLink from "@/Components/FakeRouterLink.vue"

const initVars = JSON.parse(atob(window.efboot))

const appName =
	window.document.getElementsByTagName("title")[0]?.innerText || "Home"

// for tracking inertia events
window.inertiaEventsCount = {
	navigateCount: 0,
	successCount: 0,
	errorCount: 0,
}
createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: (name) =>
		resolvePageComponent(
			`./Dashboard/Pages/${name}.vue`,
			import.meta.glob("./Dashboard/Pages/**/*.vue"),
		),
	setup({ el, App, props, plugin }) {
		const vueApp = createApp({ render: () => h(App, props) })
			.use(plugin)
			.use(VueFroala)
			.use(VueGridLayout)

		registerPlugins(vueApp)

		// fake router link for libraries that depend on it...
		vueApp.component("RouterLink", FakeRouterLink)

		vueApp.mount(el)

		onUnmounted(
			router.on("navigate", (event) => {
				window.inertiaEventsCount.navigateCount++
			}),
			router.on("success", (event) => {
				window.inertiaEventsCount.successCount++
			}),
			router.on("error", (event) => {
				window.inertiaEventsCount.errorCount++
			}),
		)
	},
	progress: {
		color: initVars.theme_secondary,
	},
})
